/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { updateCartFetcher, useCartSWRKey } from '@/data/Content/Cart';
import { useExternalInventory } from '@/data/Content/ExternalInventory';
import { useNotifications } from '@/data/Content/Notifications';
import { useProduct } from '@/data/Content/Product';
import { useProductXRef } from '@/data/Content/ProductXRef';
import { useExtraRequestParameters } from '@/data/Content/_ExtraRequestParameters';
import { useSettings } from '@/data/Settings';
import { EMPTY_STRING } from '@/data/constants/marketing';
import { ORDER_CONFIGS } from '@/data/constants/order';
import { TransactionErrorResponse } from '@/data/types/Basic';
import { CatSEO } from '@/data/types/Category';
import { OrderItem, OrderItemExtendAttribute } from '@/data/types/Order';
import { ProductDisplayPrice, ProductType, ResponseProductAttribute } from '@/data/types/Product';
import { dFix } from '@/data/utils/floatingPoint';
import { cartMutatorKeyMatcher } from '@/data/utils/mutatorKeyMatchers/cartMutatorKeyMatcher';
import { usableShippingInfoMutatorKeyMatcher } from '@/data/utils/mutatorKeyMatchers/usableShippingInfoMutatorKeyMatcher';
import { processError } from '@/data/utils/processError';
import { get, isEmpty, partition } from 'lodash';
import { useCallback, useMemo } from 'react';
import { mutate } from 'swr';
// eslint-disable-next-line no-restricted-imports
import { getProductAttributeValue } from '@/utils/getProductAttribute';

export type ColumnWithKey = {
	key: string;
	numeric?: boolean;
	[extra: string]: any;
};

type UpdatedOrderItem = OrderItem & {
	xitem_field2?: string;
};

export const useOrderItemTable = (
	orderItems: OrderItem[],
	orderId: string,
	_physicalStoreName?: string
) => {
	const { settings } = useSettings();
	const { storeId } = settings;
	const params = useExtraRequestParameters();
	const { notifyError } = useNotifications();
	const currentCartSWRKey = useCartSWRKey(); // in current language

	const updateOrderItem = useCallback(
		(orderItemId: string) => async (quantity: number | null) => {
			if (quantity !== null && orderItemId) {
				const orderItem = {
					quantity: quantity.toString(),
					orderItemId,
				};

				const data = {
					orderId,
					x_calculateOrder: ORDER_CONFIGS.calculateOrder,
					orderItem: [orderItem],
					x_calculationUsage: ORDER_CONFIGS.calculationUsage,
					x_inventoryValidation: ORDER_CONFIGS.inventoryValidation.toString(),
				};
				try {
					await updateCartFetcher(true)(storeId ?? '', {}, data, params);
					await mutate(cartMutatorKeyMatcher(EMPTY_STRING)); // at current page
					await mutate(cartMutatorKeyMatcher(currentCartSWRKey), undefined); // all cart except current cart, e.g different locale
					await mutate(usableShippingInfoMutatorKeyMatcher(EMPTY_STRING), undefined);
				} catch (e) {
					notifyError(processError(e as TransactionErrorResponse));
				}
			}
		},
		[orderId, storeId, params, currentCartSWRKey, notifyError]
	);

	const data = useMemo(
		() =>
			orderItems
				? orderItems.map((orderItem: UpdatedOrderItem) => {
						const {
							partNumber,
							quantity,
							orderItemId,
							orderItemPrice,
							unitPrice,
							currency,
							contractId,
							xitem_field2,
							freeGift,
						} = orderItem || {};

						const inStock = orderItem.orderItemExtendAttribute?.filter(
							(attr) => attr.attributeName === 'INV_IN_STOCK'
						)[0]?.attributeValue;
						const leadTime = orderItem.orderItemExtendAttribute?.filter(
							(attr: OrderItemExtendAttribute) => attr.attributeName === 'INV_LEAD_TIME'
						)[0]?.attributeValue;

						return {
							itemDetails: {
								partNumber,
								orderItemId,
								contractId,
								currency,
								unitPrice,
								key: 'partNumber',
								xitem_field2: xitem_field2 ?? '',
								inStock: inStock === '1',
								hasLeadTime: !isEmpty(leadTime),
								leadTime,
							},
							availability: {
								availability: null,
								loading: null,
								error: null,
								key: 'availability',
							},
							quantity: {
								quantity: dFix(quantity, 0),
								onChange: updateOrderItem(orderItemId),
								key: 'quantity',
								numeric: true,
							},
							price: {
								orderItemPrice,
								currency,
								key: 'orderItemPrice',
								numeric: true,
							},
							freeGift: freeGift?.toLowerCase() === 'true',
						};
					})
				: [],
		[orderItems, updateOrderItem]
	);

	return {
		data,
	};
};

const EMPTY_SEO = {} as CatSEO;
const EMPTY_PRICE = {} as ProductDisplayPrice;
const EMPTY_PROD = {} as ProductType;
const EMPTY_ATTRS: ResponseProductAttribute[] = [];

export const useOrderItemTableRow = (
	partNumber: string,
	contractId?: string | string[],
	_orderItemId = '',
	checkInventory = true
) => {
	const { product = EMPTY_PROD, loading } = useProduct({ id: partNumber, contractId });
	const { data: xrefData } = useProductXRef(partNumber);

	const specialOrder = getProductAttributeValue('WH01_Special_Order', product?.attributes);
	const specialOrderFlag = specialOrder && specialOrder === 'Yes' ? true : false;

	const specialOrderMinQty = getProductAttributeValue(
		'WH01_Special_Order_Minimum',
		product?.attributes
	);
	const specialOrderQtyLimit =
		specialOrderMinQty && parseInt(specialOrderMinQty) > 0 ? parseInt(specialOrderMinQty) : null;

	// use the parent partNumber for inventory call
	const { product: parentProduct } = useProduct({
		id: product?.parentCatalogEntryID,
		isCEId: true,
		condition: product?.type === 'item',
	});
	const { data: externalInventoryData }: any = useExternalInventory(
		checkInventory ? parentProduct?.partNumber : ''
	);

	const extInventoryQuantity = useMemo(() => {
		if (externalInventoryData?.products) {
			const productKeys = Object.keys(externalInventoryData?.products);
			const firstProductKey = productKeys?.[0];
			const extQuantity =
				externalInventoryData?.products?.[firstProductKey]?.items?.[partNumber]?.quantity;
			return Number(extQuantity);
		}
	}, [externalInventoryData, partNumber]);

	const {
		attributes = EMPTY_ATTRS,
		name = '',
		productPrice = EMPTY_PRICE,
		seo: { href = '' } = EMPTY_SEO,
		manufacturer = '',
		thumbnail = '',
		thumbnailRaw = '',
		sellerId,
		seller,
	} = product;
	const [colorAttributes, otherAttributes] = partition(
		attributes,
		({ identifier }) => identifier === 'Color'
	);
	const color = get(colorAttributes[0], 'values[0].value', '');

	return {
		details: {
			partNumber,
			name,
			color,
			thumbnail,
			thumbnailRaw,
			href,
			prices: productPrice,
			attributes: otherAttributes,
			manufacturer,
			seller,
			sellerId,
			loading,
			xrefPartNumber: xrefData?.xrefPartNumber,
			extInventoryQuantity,
			checkInventory,
			specialOrderFlag,
			specialOrderQtyLimit,
		},
	};
};
