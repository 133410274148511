export const getProductAttributeValue = (attributeValue: string, identifierArray: any) => {
	const getSelectedIdentifier = identifierArray?.filter(
		(item: { identifier: string }) => item.identifier === attributeValue
	);
	if (getSelectedIdentifier?.length > 0) {
		return getSelectedIdentifier?.[0]?.values?.[0]?.value;
	} else {
		return '';
	}
};
